import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import {
  StyledOrderDetailCross,
  LoaderWrapper,
  StyledContainer,
  StyledHR,
  AdaptiveContainer,
  TipPanel,
} from './order-detail.component.styles'

import OrderSharingMenu from './order-sharing/order-sharing-menu.component'
import PrintButton from './order-sharing/print-button.component'
import { ShippingInfo } from './shipping-info/shipping-info.component'
import { PriceBreakdown } from './shipping-info/price-breakdown/price-breakdown.component'
import { PaymentMethod } from './shipping-info/payment-method/payment-method.component'
import { RouteredOrderActions } from './order-actions/order-actions.component'
import { OrderStatus } from './order-status/order-status.component'
import { OrderMap } from './order-status/order-map/order-map.component'
import { DriverRating } from './driver-rating/driver-rating.component'
import { DriverInfo } from './driver-info/driver-info.component'

import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { getCountry } from 'ggx-service/locale/country.service'

import {
  Heading,
  BodySemiBold,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import { getLocale } from 'ggx-src/legacy/utils/locale'
import { LoadingSpinner } from 'ggx-src/legacy/views/helpers/loading/spinner'
import { orderService } from '../../van-booking/api/order-api.service'

import { apiService as orderRequestService } from '../api/api.service'
import { priceQuotationService } from 'ggx-service/api/price-quotation.service'
import { VEHICLE_TRANSLATIONS } from 'ggx-service/vehicle/vehicle'
import { BonusModal } from './bonus-modal/bonus-modal.component'

import BET_3668 from 'ggx-src/feat/BET_3668'

import { LINE_HEIGHT } from 'ggx-componentlibrary/design/typography/typography.constants'

const whatsappUrlFormatter = ({ t, order, traceURL }) => {
  const startUrl = 'https://wa.me/?text='
  if (!order?.waypoints) {
    return startUrl
  }
  const pickupAddress = order.waypoints[0]?.address
  const dropoffAddress = order.waypoints[order.waypoints.length - 1]?.address
  const defaultContent = t('share_content__transport__before', {
    orderId: order.id,
    startAddr: pickupAddress,
    endAddr: dropoffAddress,
    tntLink: traceURL,
  })

  switch (order.status) {
    case 'active':
      return (
        startUrl +
        encodeURIComponent(
          t('share_content__transport__during', {
            orderId: order.id,
            startAddr: pickupAddress,
            endAddr: dropoffAddress,
            driverName: order.order?.driver_nickname || '',
            contactNo: order.order?.driver_phone_number || '',
            licensePlate: order.order?.driver_license_plate || '',
            tntLink: traceURL,
          })
        )
      )
    case 'completed':
      return (
        startUrl +
        encodeURIComponent(
          t('share_content__transport__after', {
            orderId: order.id,
            startAddr: pickupAddress,
            endAddr: dropoffAddress,
            driverName: order.order?.driver_nickname || '',
            tntLink: traceURL,
          })
        )
      )
    default:
      return startUrl + encodeURIComponent(defaultContent)
  }
}

const OrderDetail = ({
  onRequestClose = () => {},
  router,
  orderContactInfo = {},
}) => {
  const [t] = useTranslation()
  const [showOrderDetail, setShowOrderDetail] = useState(false)
  const [order, setOrder] = useState({})
  const [breakdownResponse, setBreakdownResponse] = useState({})
  const [loading, setLoading] = useState(false)
  const selectedOrderId = router?.params?.id
  const inProgressOrder =
    order?.status === 'active' || order?.status === 'pending'

  const notifications = useSelector(state => state.notification?.messages)
  const notificationsPreviousCount = useRef(notifications.count)

  let locale = getLocale()
  if (locale === 'en-hk' || locale === 'en-sg') {
    locale = 'en'
  } else if (locale === 'vi-vn') {
    locale = 'vi'
  }
  const country = getCountry()
  const traceURL = `${
    process.env.TRACK_AND_TRACE_URL
  }/${locale}/${country.toLowerCase()}/transport/${order.id}`

  const fetchOrderId = useCallback(async ({ id } = {}) => {
    setLoading(true)
    setShowOrderDetail(true)

    priceQuotationService
      .getBreakdown(id)
      .then(setBreakdownResponse)
      .catch(console.error)

    try {
      const { id: oldId, nid, ...result } = await orderService.getOrder(id)
      setOrder({
        ...result,
        id: nid || oldId,
        oldId,
      })
    } catch (error) {
      setShowOrderDetail(false)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (selectedOrderId !== undefined) {
      fetchOrderId({ id: selectedOrderId })
    }
  }, [selectedOrderId, fetchOrderId])

  useEffect(() => {
    const timerInterval = 120000 // 2 minutes
    const intervalId = setInterval(() => {
      if (selectedOrderId && showOrderDetail && inProgressOrder) {
        fetchOrderId({ id: selectedOrderId })
      }
    }, timerInterval)
    return () => clearInterval(intervalId)
  }, [showOrderDetail, selectedOrderId, fetchOrderId, inProgressOrder])

  useEffect(() => {
    const lastNotificationOrderId = notifications?.slice(-1)[0]?.orderRequestId
    const lastNotificationOrderIdIsSelected =
      order?.id === lastNotificationOrderId

    if (
      selectedOrderId !== undefined &&
      notifications.length > notificationsPreviousCount.current &&
      lastNotificationOrderIdIsSelected
    ) {
      fetchOrderId({ id: selectedOrderId })
    }

    notificationsPreviousCount.current = notifications.length
  }, [notifications, fetchOrderId, selectedOrderId, order])

  const handleCloseOrderDetail = () => {
    setShowOrderDetail(false)
    onRequestClose()
  }

  const handleCopyLink = () => {
    copy(traceURL)
    displayAlert({ message: t('text__tracking_link_copied_to_clipboard') })
  }

  const whatsappURL = useMemo(() => {
    return whatsappUrlFormatter({ t, order, traceURL })
  }, [order.id, order.status])

  const [sm, setSM] = useState(false)
  const drawerRef = useRef()
  useEffect(() => {
    if (!loading && order !== undefined) {
      const panel = drawerRef.current.querySelector('.MuiPaper-root')
      const resizeObserver = new ResizeObserver(() =>
        setSM(panel.offsetWidth < 590)
      )
      resizeObserver.observe(panel)
      return () => resizeObserver.disconnect()
    }
  }, [loading, order])

  const [closeFloatRatingBlock, setCloseFloatRatingBlock] = useState(false)
  useEffect(() => {
    setCloseFloatRatingBlock(
      (order?.order?.driver_rating ?? null) === null ? false : true
    )
  }, [order?.order?.driver_rating])

  const [showTipModel, setShowTipModel] = useState(false)

  return (
    <Drawer
      ref={drawerRef}
      open={showOrderDetail}
      anchor="right"
      variant="persistent"
      data-testid="order-detail-drawer"
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '1000px',
          minWidth: '400px',
          width: '47.43055vw',
          padding: '28px',
          ...BET_3668.adjustment.drawerStyle,
        },
      }}
      close
    >
      <BET_3668.ResizingSpace
        onDrag={offset => setWidth(width => width - offset)}
      />
      {loading || order === undefined ? (
        <>
          <StyledContainer
            style={{ alignItems: 'end', height: 'auto', paddingBottom: 0 }}
          >
            <StyledOrderDetailCross onClick={handleCloseOrderDetail} />
          </StyledContainer>
          <LoaderWrapper>
            <LoadingSpinner />
          </LoaderWrapper>
        </>
      ) : (
        <StyledContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '18px',
              maxHeight: LINE_HEIGHT.LARGE,
            }}
          >
            <Heading
              style={{ padding: 0, marginRight: 'auto' }}
              typographyStyles="mb-none"
            >
              {t('text__order_id_vehicle_new', {
                number: `#${order.id}`,
                vehicle: t(VEHICLE_TRANSLATIONS[order.vehicle]),
              })}
            </Heading>
            {order.status === 'completed' ? (
              <PrintButton $sm={sm} orderId={order.id} />
            ) : null}
            <OrderSharingMenu
              $sm={sm}
              uuid={order.uuid}
              status={order.status}
              whatsappURL={whatsappURL}
              handleCopyLink={handleCopyLink}
              sendOrderReceipt={orderRequestService.sendOrderReceipt}
            />
            <StyledOrderDetailCross onClick={handleCloseOrderDetail} />
          </div>
          <StyledHR />
          <AdaptiveContainer $sm={sm}>
            <OrderStatus order={order} />
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
            >
              <OrderMap order={order} />
              {order.status === 'pending' && (
                <TipPanel>
                  <div>
                    <BodySemiBold typographyStyles="mb-xxs">
                      {t('text__looking_for_a_driver_new')}
                    </BodySemiBold>
                    <SmallText typographyStyles="mb-none">
                      {t('adding_a_tip_can_driver')}
                    </SmallText>
                  </div>
                  <BodySemiBold
                    typographyStyles="c-blue_medium mb-none"
                    style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={() => setShowTipModel(true)}
                  >
                    {order?.bonus
                      ? t('tip_amount', {
                          amount: formatCurrency({ amount: order?.bonus }),
                        })
                      : t('add_tip_driver')}
                  </BodySemiBold>
                  {showTipModel && (
                    <BonusModal
                      onModalClose={() => setShowTipModel(false)}
                      id={order.oldId}
                      initialValue={parseInt(order?.bonus, 10) || 0}
                      onBonusAdded={() => fetchOrderId({ id: selectedOrderId })}
                      vehicle={order.vehicle}
                    />
                  )}
                </TipPanel>
              )}
              {order.status === 'active' && (
                <DriverInfo
                  order={order}
                  onUpdateSuccess={() => fetchOrderId({ id: selectedOrderId })}
                />
              )}
              {order.status === 'completed' &&
                (!closeFloatRatingBlock ? (
                  <DriverRating
                    orderId={order.id}
                    driverPhoto={order?.order?.driver_profile_photo}
                    name={order?.order?.driver_nickname}
                    onRatingSuccess={() =>
                      fetchOrderId({ id: selectedOrderId })
                    }
                    onClose={() => setCloseFloatRatingBlock(true)}
                  />
                ) : (
                  <DriverInfo
                    order={order}
                    onUpdateSuccess={() =>
                      fetchOrderId({ id: selectedOrderId })
                    }
                  />
                ))}
            </div>
          </AdaptiveContainer>
          <StyledHR />
          <AdaptiveContainer $sm={sm}>
            <ShippingInfo
              order={order}
              orderContactInfo={orderContactInfo}
              breakdownResponse={breakdownResponse}
            />
            <div>
              <PriceBreakdown
                order={order}
                breakdownResponse={breakdownResponse}
              />
              <PaymentMethod order={order} />
            </div>
          </AdaptiveContainer>
          <StyledHR />
          <RouteredOrderActions
            orderId={order.id}
            orderUuid={order.uuid}
            orderContactPhoneNumber={order.phone_number}
            waypoints={order.waypoints}
            status={order.status}
            onOrderCancelled={fetchOrderId}
            breakdown={breakdownResponse?.breakdown}
          />
        </StyledContainer>
      )}
    </Drawer>
  )
}

OrderDetail.propTypes = {
  onRequestClose: PropTypes.func,
  router: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

const RouteredOrderDetail = withRouter(OrderDetail)

export { OrderDetail, RouteredOrderDetail }
